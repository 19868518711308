import Card from 'components/arc-loves/Card'
import PostModal from 'components/arc-loves/PostModal'
import { TextInput } from 'components/global/FormInputs'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import AltFooter from 'components/Layout/Footer/AltFooter'
import { H2 } from 'components/typography/Headings'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

export default function ARCLovesPage({ location }: PageProps) {
	const posts = useStaticQuery<ARCLovesQuery>(query).allContentfulArcLoves.edges
	const params = new URLSearchParams(location.search)
	const postId = params.get('id')

	const [featured] = useState(posts.filter((item) => item.node.featured).slice(0, 2))
	const [post, setPost] = useState<ARCLoves | null>(null)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [search, setSearch] = useState('')
	const [postFilter, setPostFilter] = useState(posts)

	const handleModalClose = () => setIsModalOpen(false)

	const handleModalContent = (id: string) => {
		const { node: post } = posts.filter((d) => d.node.id === id)[0]
		setPost(post)
		setIsModalOpen(true)
	}

	useEffect(() => {
		let postsFilter
		// If there is no search, filter out the featured posts so they dont show up twice
		if (!search) {
			postsFilter = posts
				// Filter posts by title and tags
				.filter((item) => item.node.title.toLowerCase().includes(search.toLowerCase()) || item.node?.tags?.toString().toLowerCase().includes(search.toLowerCase()))
				// Filter out the featured posts
				.filter((item) => item.node.id !== featured[0].node.id && item.node.id !== featured[1].node.id)
			// If there is search, then we can show the featured posts in the results
		} else {
			postsFilter = posts.filter(
				(item) => item.node.title.toLowerCase().includes(search.toLowerCase()) || item.node?.tags?.toString().toLowerCase().includes(search.toLowerCase())
			)
		}
		setPostFilter(postsFilter)
	}, [search])

	useEffect(() => {
		if (postId) handleModalContent(postId)
	}, [postId])

	return (
		<>
			<Helmet>
				<script defer src="https://www.sociablekit.com/app/embed/twitter-hashtag-feed/widget.js"></script>
			</Helmet>
			<Layout pageName="ARC Loves">
				<Section backgroundColor="bg-ab-100">
					<Row className="flex-col pt-20 pb-8" middle>
						<Col width="w-full md:w-1/2">
							<div>
								<h1 className="bg-ar-100 p-6">
									<StaticImage data-cy="arc-loves-logo" src="../../static/images/logos/arc-loves.png" loading="eager" alt="arc loves logo" />
								</h1>
							</div>
							<span className="mt-3 block text-center text-3xl font-bold tracking-wider text-white">Love • Literacy • Liberty</span>
						</Col>
					</Row>
				</Section>
				<Section margin="mt-20">
					<Row>
						<Col width="w-full md:w-1/2 xl:w-1/3" className="hidden md:block" gutter="lg">
							<div className="sk-ww-twitter-hashtag-feed" data-embed-id="56644"></div>
						</Col>
						<Col width="w-full md:w-1/2 xl:w-2/3" gutter="lg">
							<Row>
								<H2 className="mx-auto mb-8">Featured Posts</H2>
							</Row>
							<Row>
								{featured.map((item) => (
									<Col key={item.node.id} width="w-full xl:w-1/2" gutter="lg" className="mb-12">
										<Card data={item.node} handleModalContent={handleModalContent} />
									</Col>
								))}
							</Row>
							<Row>
								<H2 className="mx-auto mb-8 mt-12">Recent Posts</H2>
							</Row>
							<Row>
								<Col>
									<div className="mb-6 mt-4 md:ml-auto md:max-w-max">
										<TextInput
											placeholder="Search all posts"
											id="arc loves search"
											name="search"
											type="text"
											search
											value={search}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								{postFilter
									// @ts-ignore
									.sort((a, b) => new Date(b.node.publishedDate) - new Date(a.node.publishedDate))
									.map((item) => (
										<Col key={item.node.id} width={`w-full xl:w-1/2`} gutter="lg" className="mb-12">
											<Card data={item.node} handleModalContent={handleModalContent} />
										</Col>
									))}
							</Row>
						</Col>
					</Row>
				</Section>
				<AltFooter />
				<PostModal post={post} isModalOpen={isModalOpen} handleModalClose={handleModalClose} />
			</Layout>
		</>
	)
}

export interface ARCLoves {
	id: string
	name: string
	title: string
	type: 'Video' | 'Slider' | 'Link'
	location: string
	featured: boolean
	homePagePosition?: number
	description: { description: string }
	publishedDate: Date
	sliderImages: {
		title: string
		id: string
		gatsbyImageData: IGatsbyImageData
	}[]
	photo: {
		gatsbyImageData: IGatsbyImageData
	}
	link: string
	video: {
		title: string
		thumbnail: {
			gatsbyImageData: IGatsbyImageData
		}
	}
	tags: string[]
	createdAt: Date
}

export interface ARCLovesQueryNode {
	node: ARCLoves
}
export interface ARCLovesQuery {
	allContentfulArcLoves: {
		edges: ARCLovesQueryNode[]
	}
}

const query = graphql`
	query {
		allContentfulArcLoves {
			edges {
				node {
					id
					name
					title
					description {
						description
					}
					type
					location
					featured
					publishedDate
					sliderImages {
						id
						gatsbyImageData(height: 975)
					}
					photo {
						title
						gatsbyImageData(height: 355)
					}
					link
					video {
						title
						thumbnail {
							gatsbyImageData(height: 975)
						}
					}
					tags
					createdAt
				}
			}
		}
	}
`
