import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { ARCLoves } from 'pages/arc-loves'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

interface PostSliderProps {
	post: ARCLoves
}

const PostSlider = ({ post }: PostSliderProps) => {
	return (
		<Carousel showThumbs={false} dynamicHeight showStatus={false} infiniteLoop showIndicators={post.sliderImages.length > 1 ? true : false}>
			{post.sliderImages.map((image) => {
				if (post.link) {
					return <PostWithLink post={post} image={image} key={image.id} />
				} else {
					return <PostNoLink post={post} image={image} key={image.id} />
				}
			})}
		</Carousel>
	)
}

export default PostSlider

interface PostWithLinkProps {
	post: ARCLoves
	image: {
		gatsbyImageData: IGatsbyImageData
	}
}

const PostWithLink = ({ post, image }: PostWithLinkProps) => {
	return (
		<a href={post.link} target="_blank" rel="noreferrer">
			<div>
				<GatsbyImage loading="eager" image={image.gatsbyImageData} alt={post.name} />
			</div>
		</a>
	)
}

interface PostNoLinkProps {
	post: ARCLoves
	image: {
		gatsbyImageData: IGatsbyImageData
	}
}

const PostNoLink = ({ image, post }: PostNoLinkProps) => {
	return (
		<div>
			<GatsbyImage loading="eager" image={image.gatsbyImageData} alt={post.name} />
		</div>
	)
}
