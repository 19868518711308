import { Col, Row, Section } from 'components/grid'
import React from 'react'

export default function AltFooter() {
	return (
		<Section backgroundColor="bg-ab-50">
			<Row className="pt-10">
				<Col>
					<span className="block text-center text-2xl text-ab-100 tracking-wide">
						<span className="pr-3">Engaged Families</span> • <span className="px-3">Inspired Teachers</span> • <span className="px-3">Transformational Leaders</span> •{' '}
						<span className="pl-3">Equitable Systems</span>
					</span>
				</Col>
			</Row>
		</Section>
	)
}
