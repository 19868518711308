import PostShare from 'components/arc-loves/PostShare'
import PostSlider from 'components/arc-loves/PostSlider'
import Modal from 'components/global/Modal'
import OverlayVideo from 'components/overlay-video'
import { ARCLoves } from 'pages/arc-loves'
import React from 'react'

interface Props {
	post: ARCLoves | null
	isModalOpen: boolean
	handleModalClose: () => void
}

const PostModal = ({ post, isModalOpen, handleModalClose }: Props) => {
	return (
		<Modal title={post?.title || ''} wide isOpen={isModalOpen} handleClose={handleModalClose}>
			{post && (
				<>
					{post.description && <p>{post.description.description}</p>}
					{post.type === 'Video' && <OverlayVideo title={post.video.title} noOverlay />}
					{post.type === 'Slider' && <PostSlider post={post} />}
					<PostShare post={post} />
				</>
			)}
		</Modal>
	)
}

export default PostModal
