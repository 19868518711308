import { GatsbyImage } from 'gatsby-plugin-image'
import { ARCLoves } from 'pages/arc-loves'
import React from 'react'

interface Props {
	data: ARCLoves
	handleModalContent: (id: string) => any
}

const Card = ({ data, handleModalContent }: Props) => {
	const isVideo = data.type === 'Video'
	const isSlider = data.type === 'Slider'

	if (isVideo) {
		return (
			<div className="arc-red-gradient flex h-full w-full flex-col items-start rounded-lg shadow-lg">
				<button data-cy="arc-loves-button" className="w-full focus:outline-none focus:ring-0" onClick={() => handleModalContent(data.id)}>
					<div className="bg-white">
						<GatsbyImage image={data.video.thumbnail.gatsbyImageData} alt={data.name} />
					</div>
					<div className="flex-grow p-4">
						<h5 className="text-2xl font-normal leading-tight text-white">{data.title}</h5>
					</div>
				</button>
			</div>
		)
	} else if (isSlider) {
		return (
			<div className="arc-red-gradient flex h-full w-full flex-col items-start rounded-lg shadow-lg">
				<button className="w-full focus:outline-none focus:ring-0" onClick={() => handleModalContent(data.id)}>
					<div className="bg-white">
						<GatsbyImage image={data.photo.gatsbyImageData} alt={data.name} />
					</div>
					<div className="flex-grow p-4">
						<h5 className="text-2xl font-normal leading-tight text-white">{data.title}</h5>
					</div>
				</button>
			</div>
		)
	}

	return (
		<div className="arc-red-gradient flex h-full w-full flex-col items-start rounded-lg shadow-lg">
			<a href={data.link} target="_blank" rel="noreferrer" className="block w-full">
				<GatsbyImage image={data.photo.gatsbyImageData} alt={data.name} />
			</a>
			<div className="flex-grow p-4">
				<a href={data.link} target="_blank" className="flex h-full items-center" rel="noreferrer">
					<h5 className="text-2xl font-normal leading-tight text-white">{data.title}</h5>
				</a>
			</div>
		</div>
	)
}

export default Card
